import React from 'react';
import './App.css';
import AppTest from './test';

function App() {
  return (
   <AppTest></AppTest>
  );
}

export default App;

