import React, { useState, useEffect } from 'react';
import { Input, Button, Space, Select } from 'antd';

const { Option } = Select;

const ParticipantInput = ({
  totalAmount,
  onTotalAmountChange,
  participantCount,
  onParticipantCountChange,
  participantData,
  onParticipantDataChange,
}) => {
  const [participantNames, setParticipantNames] = useState(['']);
  const [participantRoles, setParticipantRoles] = useState(['L/EA']);

  const handleParticipantCountChange = (count) => {
    count = Math.max(count, 1);
    onParticipantCountChange(count);

    if (count > participantNames.length) {
      const updatedNames = [...participantNames];
      const updatedRoles = [...participantRoles];
      const updatedData = [...participantData];

      for (let i = participantNames.length; i < count; i++) {
        updatedNames.push('');
        updatedRoles.push('L/EA');
        updatedData.push({ name: '', role: 'L/EA' });
      }

      setParticipantNames(updatedNames);
      setParticipantRoles(updatedRoles);
      onParticipantDataChange(updatedData);
    } else if (count < participantNames.length) {
      const updatedNames = participantNames.slice(0, count);
      const updatedRoles = participantRoles.slice(0, count);
      const updatedData = participantData.slice(0, count);
      setParticipantNames(updatedNames);
      setParticipantRoles(updatedRoles);
      onParticipantDataChange(updatedData);
    }
  };

  const handleParticipantNameChange = (index, name) => {
    const updatedNames = [...participantNames];
    updatedNames[index] = name;
    setParticipantNames(updatedNames);

    const updatedData = participantData.map((data, i) => {
      if (i === index) {
        return { ...data, name };
      }
      return data;
    });

    onParticipantDataChange(updatedData);
  };

  const handleParticipantRoleChange = (index, role) => {
    const updatedRoles = [...participantRoles];
    updatedRoles[index] = role;
    setParticipantRoles(updatedRoles);

    const updatedData = participantData.map((data, i) => {
      if (i === index) {
        return { ...data, role };
      }
      return data;
    });

    onParticipantDataChange(updatedData);
  };

  const handleTotalAmountChange = (amount) => {
    const regex = /^[0-9]+$/;
    if (amount === '' || regex.test(amount)) {
      const convertedAmount = amount.replace(/[０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
      const limitedAmount = convertedAmount.slice(0, 10);

      onTotalAmountChange(limitedAmount);
    }
  };

    // 参加人数の変更を検知してプロパティとして渡す
    useEffect(() => {
      handleParticipantCountChange(participantCount);
    }, [participantCount]);

  useEffect(() => {
    onParticipantDataChange(participantData);
    setParticipantNames(participantData.map((data) => data.name));
    setParticipantRoles(participantData.map((data) => data.role));
  }, [participantData, onParticipantDataChange]);

  return (
    <div
      style={{
        background: '#FFFFFF',
        padding: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: '16px' }}>
        <Space>
          <Button
            onClick={() => handleParticipantCountChange(participantCount - 1)}
            style={{ backgroundColor: '#2674BA', color: '#FFFFFF', fontWeight: 'bold' }}
          >
            -
          </Button>
          {participantCount}
          <Button
            onClick={() => handleParticipantCountChange(participantCount + 1)}
            style={{ backgroundColor: '#2674BA', color: '#FFFFFF', fontWeight: 'bold' }}
          >
            +
          </Button>
        </Space>
      </div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', marginBottom: '8px' }}>
          <div style={{ marginRight: '8px' }}>合計金額</div>
          <Input
            style={{ width: '180px', border: '2px solid #2674BA', borderRadius: '5px' }}
            placeholder="金額"
            value={totalAmount}
            onChange={(e) => handleTotalAmountChange(e.target.value)}
          />
          <div>円</div>
        </div>
        {participantNames.map((name, index) => (
          <div key={index} style={{ display: 'flex', marginBottom: '8px' }}>
            <Input
              style={{ marginRight: '8px', border: '2px solid #2674BA', borderRadius: '5px' }}
              placeholder={`参加者 ${index + 1} 名前`}
              value={name}
              onChange={(e) => handleParticipantNameChange(index, e.target.value)}
            />
            <Select
              style={{ width: '180px', border: '2px solid #2674BA', borderRadius: '5px' }}
              placeholder="役職"
              value={participantRoles[index]}
              onChange={(value) => handleParticipantRoleChange(index, value)}
            >
              <Option value="BM">BM</Option>
              <Option value="M/EM">M/EM</Option>
              <Option value="L/EA">L/EA</Option>
              <Option value="J/E">J/E</Option>
            </Select>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParticipantInput;
