import React, { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import ParticipantInput from './components/ParticipantInput';
import SplitCalculation from './components/SplitCalculation';

const { Header, Content, Footer } = Layout;

const AppTest = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [currentScreen, setCurrentScreen] = useState('participant-input');
  const [totalAmount, setTotalAmount] = useState('');
  const [participantCount, setParticipantCount] = useState('');
  const [participantData, setParticipantData] = useState([{ name: '', role: '' },]);


  const handleMenuClick = (e) => {
    setCurrentScreen(e.key);
  };

  const handleTotalAmountChange = (amount) => {
    setTotalAmount(amount);
  };

  const handleParticipantCountChange = (count) => {
    setParticipantCount(count);
  };  

  const handleParticipantDataChange = (data) => {
    setParticipantData(data)
  };

  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Header style={{ display: 'flex', alignItems: 'center', background: '#001326' }}>
        <div className="demo-logo" />
        <Menu
          // theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['participant-input']}
          selectedKeys={[currentScreen]}
          onClick={handleMenuClick}
          style={{ background: '#001326' }}
        >
          <Menu.Item 
            key="participant-input"
            style={{ color: '#E8E8E8', fontWeight: 'bold', 
            backgroundColor: currentScreen === 'participant-input' ? '#002C59' : undefined,
          }
           } 
          >参加者入力</Menu.Item>
          <Menu.Item
           key="split-calculation"
           style={{ color: '#E8E8E8', fontWeight: 'bold',
           backgroundColor: currentScreen === 'split-calculation' ? '#002C59' : undefined,
          }
          }
           >割り勘計算</Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
        <div className="site-layout-content" style={{ background: colorBgContainer }}>
          {currentScreen === 'participant-input' && 
           <ParticipantInput
           totalAmount={totalAmount}
           onTotalAmountChange={handleTotalAmountChange}
           participantCount={participantCount}
           onParticipantCountChange={handleParticipantCountChange}
           participantData={participantData}
           onParticipantDataChange={handleParticipantDataChange}
           initialParticipantCount={1} // 初期表示の参加者数

         />
          } 
          {currentScreen === 'split-calculation' &&
           <SplitCalculation
            totalAmount={totalAmount}
            participantData={participantData}
            defaultRole="L/EA" 
 
            />}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>割り勘アプリ ©2023 Created by さちこ</Footer>
    </Layout>
  );
};

export default AppTest;
