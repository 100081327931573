// import React from 'react';
// import { Card } from 'antd';

// const { Meta } = Card;

// const SplitCalculation = ({ totalAmount, participantData }) => {
//   const participantCount = participantData.length;

//   const calculatePerPersonAmount = () => {
//     if (participantCount === 0) {
//       return 0;
//     }

//     const totalAmountNumeric = parseFloat(totalAmount);
//     const bmCount = countParticipantsByRole('BM');
//     const highannualCount = countParticipantsByRole('年次高め');
//     const regularCount = countParticipantsByRole('年次普通');
//     const subordinateCount = countParticipantsByRole('下っ端');

//     const totalCoefficient = (5 * bmCount)+ (3 * highannualCount) + (2 * regularCount) + subordinateCount;
//     const perPersonAmount = totalAmountNumeric / totalCoefficient;
//     return perPersonAmount.toFixed(2);
//   };

//   const calculateIndividualAmounts = () => {
//     const individualAmounts = {};

//     participantData.forEach((data, index) => {
//       const roleCoefficient = getRoleCoefficient(data.role || '年次普通');
//       let individualAmount = calculatePerPersonAmount() * roleCoefficient;
//       individualAmount = Math.ceil(individualAmount / 100) * 100; // 100円単位に切り上げ
//       individualAmounts[data.name] = individualAmount.toFixed(2);
//     });

//     return individualAmounts;
//   };

//   const getRoleCoefficient = (role) => {
//     switch (role) {
//       case 'BM':
//         return 5;
//       case '年次高め':
//         return 3;
//       case '年次普通':
//         return 2;
//       case '下っ端':
//         return 1;
//       default:
//         return 1;
//     }
//   };

//   const countParticipantsByRole = (role) => {
//     return participantData.filter((data) => (data.role || '年次普通') === role).length;
//   };

//   const individualAmounts = calculateIndividualAmounts();

//   return (
//     <Card title="割り勘計算結果" style={{ width: 300 }}>
//       <Meta title={`合計金額: ${totalAmount} 円`} />
//       <Meta title={`参加者数: ${participantCount} 人`} />
//       <Meta title={`1人当たりの金額: ${calculatePerPersonAmount()} 円`} />
//       {participantData.map((data, index) => (
//         <Meta
//           key={index}
//           title={`${index + 1}: ${data.name} (${data.role || '年次普通'}): ${individualAmounts[data.name]} 円`}
//         />
//       ))}
//     </Card>
//   );
// };

// export default SplitCalculation;
import React from 'react';
import { Card, Table } from 'antd';

const { Meta } = Card;

const SplitCalculation = ({ totalAmount, participantData }) => {
  const participantCount = participantData.length;

  const calculatePerPersonAmount = () => {
    if (participantCount === 0) {
      return 0;
    }

    const totalAmountNumeric = parseFloat(totalAmount);
    const bmCount = countParticipantsByRole('BM');
    const highannualCount = countParticipantsByRole('M/EM');
    const regularCount = countParticipantsByRole('L/EA');
    const subordinateCount = countParticipantsByRole('J/E');

    const totalCoefficient = 5 * bmCount + 3 * highannualCount + 2 * regularCount + subordinateCount;
    const perPersonAmount = totalAmountNumeric / totalCoefficient;
    

    return perPersonAmount.toFixed(2);
  };

  const calculateIndividualAmounts = () => {
    const individualAmounts = {};

    participantData.forEach((data, index) => {
      const roleCoefficient = getRoleCoefficient(data.role || 'L/EA');
      let individualAmount = calculatePerPersonAmount() * roleCoefficient;
      individualAmount = Math.ceil(individualAmount / 100) * 100; // 100円単位に切り上げ
      individualAmounts[data.name] = individualAmount.toFixed(0);
    });

    return individualAmounts;
  };

  const getRoleCoefficient = (role) => {
    switch (role) {
      case 'BM':
        return 5;
      case 'M/EM':
        return 3;
      case 'L/EA':
        return 2;
      case 'J/E':
        return 1;
      default:
        return 1;
    }
  };

  const countParticipantsByRole = (role) => {
    return participantData.filter((data) => (data.role || 'L/EA') === role).length;
  };

  const individualAmounts = calculateIndividualAmounts();

  const dataSource = participantData.map((data, index) => ({
    key: index,
    name: data.name,
    role: data.role || 'L/EA',
    amount: individualAmounts[data.name],
  }));

  const columns = [
    {
      title: '参加者名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text) => <span style={{ color: '#1890ff' }}>{text}</span>,
    },
    {
      title: '役職',
      dataIndex: 'role',
      key: 'role',
      align: 'center',
      render: (text) => <span style={{ color: '#1890ff' }}>{text}</span>,
    },
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (text) => <span style={{ color: '#1890ff' }}>{text} 円</span>,
    },
  ];

  return (
    <Card  style={{ width: '100%', textAlign: 'center', backgroundColor: '#f0f2f5' }}>
      <Meta title={`合計金額: ${totalAmount} 円`} style={{ marginBottom: '10px' }} />
      <Meta title={`参加者数: ${participantCount} 人`} style={{ marginBottom: '10px' }} />
      {/* <Meta title={`1人当たりの金額: ${calculatePerPersonAmount()} 円`} style={{ marginBottom: '10px' }} /> */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered={false}
        showHeader={false}
        style={{ backgroundColor: '#ffffff' }}
      />
    </Card>
  );
};

export default SplitCalculation;
